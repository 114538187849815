// import React, { useState, useEffect } from 'react'
// import styled from 'styled-components'
// import { isAddress } from '../../utils/index.js'
// import EthereumLogo from '../../assets/eth.png'

// const BAD_IMAGES = {}

// const Inline = styled.div`
//   display: flex;
//   align-items: center;
//   align-self: center;
// `

// const Image = styled.img`
//   width: ${({ size }) => size};
//   height: ${({ size }) => size};
//   background-color: white;
//   border-radius: 50%;
//   box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
// `

// const StyledEthereumLogo = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   > img {
//     width: ${({ size }) => size};
//     height: ${({ size }) => size};
//   }
// `

// export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
//   const [error, setError] = useState(false)

//   useEffect(() => {
//     setError(false)
//   }, [address])

//   if (error || BAD_IMAGES[address]) {
//     return (
//       <Inline>
//         <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
//           🤔
//         </span>
//       </Inline>
//     )
//   }

//   // hard coded fixes for trust wallet api issues
//   if (address?.toLowerCase() === '0x5e74c9036fb86bd7ecdcb084a0673efc32ea31cb') {
//     address = '0x42456d7084eacf4083f1140d3229471bba2949a8'
//   }

//   if (address?.toLowerCase() === '0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f') {
//     address = '0xc011a72400e58ecd99ee497cf89e3775d4bd732f'
//   }

//   if (address?.toLowerCase() === '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2') {
//     return (
//       <StyledEthereumLogo size={size} {...rest}>
//         <img
//           src={EthereumLogo}
//           style={{
//             boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
//             borderRadius: '24px',
//           }}
//           alt=""
//         />
//       </StyledEthereumLogo>
//     )
//   }

//   const path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
//     address
//   )}/logo.png`

//   return (
//     <Inline>
//       <Image
//         {...rest}
//         alt={''}
//         src={path}
//         size={size}
//         onError={(event) => {
//           BAD_IMAGES[address] = true
//           setError(true)
//           event.preventDefault()
//         }}
//       />
//     </Inline>
//   )
// }
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import EthereumLogo from '../../assets/eth.png'
import autonio from '../../assets/autonio.png'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }

  let customPath

  // hard coded fixes for trust wallet api issues
  if (address?.toLowerCase() === '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270') {
    address = '0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0'
  }
  if (address?.toLowerCase() === '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063') {
    address = '0x6B175474E89094C44Da98b954EedeAC495271d0F'
  }
  if (address?.toLowerCase() === '0xc2132d05d31c914a87c6611c10748aeb04b58e8f') {
    address = '0xdAC17F958D2ee523a2206206994597C13D831ec7'
  }
  if (address?.toLowerCase() === '0x2791bca1f2de4661ed88a30c99a7a9449aa84174') {
    address = '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'
  }

  if (address?.toLowerCase() === '0xb33eaad8d922b1083446dc23f610c2567fb5180f') {
    address = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984'
  }

  if (address?.toLowerCase() === '0xdb3b3b147a030f032633f6c4bebf9a2fb5a882b5') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/assets/easyfi-token.png'
  }

  if (address?.toLowerCase() === '0x831753dd7087cac61ab5644b308642cc1c33dc13') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/public/favicon.jpeg'
  }

  if (address?.toLowerCase() === '0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6') {
    address = '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'
  }

  if (address?.toLowerCase() === '0x385eeac5cb85a38a9a07a70c73e0a3271cfb54a7') {
    customPath = 'https://etherscan.io/token/images/AavegotchiGHST_32.png'
  }

  if (address?.toLowerCase() === '0x4ebde54ba404be158262ede801744b92b9878c61') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/assets/easyfi-token.png'
  }

  if (address?.toLowerCase() === '0xa1c09c8f4f5d03fcc27b456475d53d988e98d7c5') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/assets/easyfi-token.png'
  }

  if (address?.toLowerCase() === '0xfc39742fe9420a7af23757fc7e78d1c3ae4a9474') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-interface/master/assets/easyfi-token.png'
  }

  if (address?.toLowerCase() === '0x9719d867a500ef117cc201206b8ab51e794d3f82') {
    customPath = 'https://aavegotchi.com/images/matokens/maUSDC.svg'
  }

  if (address?.toLowerCase() === '0x043a3aa319b563ac25d4e342d32bffb51298db7b') {
    customPath = 'https://etherscan.io/token/images/swirge_32.png'
  }

  if (address?.toLowerCase() === '0x03247a4368a280bec8133300cd930a3a61d604f6') {
    customPath = 'http://rebalancetoken.io/images/logo/RBAL_ERC20_small_001_256.png'
  }

  if (address?.toLowerCase() === '0x840195888db4d6a99ed9f73fcd3b225bb3cb1a79') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/sx.jpg'
  }

  if (address?.toLowerCase() === '0xe0b22e0037b130a9f56bbb537684e6fa18192341') {
    customPath = 'https://aavegotchi.com/images/matokens/maDAI.svg'
  }

  if (address?.toLowerCase() === '0x20D3922b4a1A8560E1aC99FBA4faDe0c849e2142'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maWETH.svg'
  }

  if (address?.toLowerCase() === '0x823CD4264C1b951C9209aD0DeAea9988fE8429bF'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maAAVE.svg'
  }

  if (address?.toLowerCase() === '0x98ea609569bD25119707451eF982b90E3eb719cD'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maLINK.svg'
  }

  if (address?.toLowerCase() === '0xDAE5F1590db13E3B40423B5b5c5fbf175515910b'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maUSDT.svg'
  }

  if (address?.toLowerCase() === '0xF4b8888427b00d7caf21654408B7CBA2eCf4EbD9'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maTUSD.svg'
  }

  if (address?.toLowerCase() === '0x8c8bdBe9CeE455732525086264a4Bf9Cf821C498'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maUNI.svg'
  }

  if (address?.toLowerCase() === '0xe20f7d1f0eC39C4d5DB01f53554F2EF54c71f613'.toLowerCase()) {
    customPath = 'https://aavegotchi.com/images/matokens/maYFI.svg'
  }

  if (address?.toLowerCase() === '0x66768ad00746aC4d68ded9f64886d55d5243f5Ec'.toLowerCase()) {
    customPath = 'https://rebalancetoken.io/images/logo/logo.png'
  }

  if (address?.toLowerCase() === '0x8d1566569d5b695d44a9a234540f68D393cDC40D'.toLowerCase()) {
    customPath = 'https://i.imgur.com/IIUglm9.png?1'
  }

  if (address?.toLowerCase() === '0x48e3883233461C2eF4cB3FcF419D6db07fb86CeA'.toLowerCase()) {
    customPath = 'https://pbs.twimg.com/profile_images/1308841930260910081/N1uVUjfS_400x400.jpg'
  }

  if (address?.toLowerCase() === '0x07738Eb4ce8932CA961c815Cb12C9d4ab5Bd0Da4'.toLowerCase()) {
    customPath = 'https://etherlegends.com/ELET.png'
  }

  if (address?.toLowerCase() === '0x23D29D30e35C5e8D321e1dc9A8a61BFD846D4C5C'.toLowerCase()) {
    customPath = 'https://hex.com/favicon.png'
  }

  if (address?.toLowerCase() === '0x0e59D50adD2d90f5111aca875baE0a72D95B4762'.toLowerCase()) {
    customPath = 'https://dark-build.app/logo192.png'
  }

  if (address?.toLowerCase() === '0xe86E8beb7340659DDDCE61727E500e3A5aD75a90'.toLowerCase()) {
    customPath = 'https://s2.gifyu.com/images/zutlogo.jpg'
  }

  if (address?.toLowerCase() === '0x3809dcDd5dDe24B37AbE64A5a339784c3323c44F'.toLowerCase()) {
    customPath = 'https://i.imgur.com/vZnU36G.png'
  }

  if (address?.toLowerCase() === '0x9f5755d47fb80100e7ee65bf7e136fca85dd9334') {
    customPath = 'https://etherscan.io/token/images/om_32.png'
  }

  if (address?.toLowerCase() === '0x104592a158490a9228070e0a8e5343b499e125d0') {
    customPath = 'https://avatars.githubusercontent.com/u/56005256?s=200&v=4'
  }

  if (address?.toLowerCase() === '0x72d6066f486bd0052eefb9114b66ae40e0a6031a') {
    customPath = 'https://etherscan.io/token/images/binance-wrx_32.png'
  }

  if (address?.toLowerCase() === '0x9c78ee466d6cb57a4d01fd887d2b5dfb2d46288f') {
    customPath = 'https://etherscan.io/token/images/cometh_32.png'
  }

  if (address?.toLowerCase() === '0x2a93172c8dccbfbc60a39d56183b7279a2f647b4') {
    customPath = 'https://raw.githubusercontent.com/sameepsi/quickswap-default-token-list/master/assets/dg.jpg'
  }

  if (address?.toLowerCase() === '0xe6fc6c7cb6d2c31b359a49a33ef08ab87f4de7ce') {
    customPath = 'https://assets.coingecko.com/coins/images/7697/small/N7aEdYrY_400x400.png?1561587437'
  }

  if (address?.toLowerCase() === '0x53e0bca35ec356bd5dddfebbd1fc0fd03fabad39') {
    address = '0x514910771AF9Ca656af840dff83E8264EcF986CA'
  }

  if (address?.toLowerCase() === '0x71b821aa52a49f32eed535fca6eb5aa130085978') {
    address = '0xB6eD7644C69416d67B522e20bC294A9a9B405B31'
  }

  if (address?.toLowerCase() === '0xa1c57f48f0deb89f569dfbe6e2b7f46d33606fd4') {
    address = '0x0F5D2fB29fb7d3CFeE444a200298f468908cC942'
  }

  if (address?.toLowerCase() === '0x8505b9d2254a7ae468c0e9dd10ccea3a837aef5c') {
    address = '0xc00e94Cb662C3520282E6f5717214004A7f26888'
  }

  if (address?.toLowerCase() === '0x313d009888329c9d1cf4f75ca3f32566335bd604') {
    address = '0x80fB784B7eD66730e8b1DBd9820aFD29931aab03'
  }

  if (address?.toLowerCase() === '0xda537104d6a5edd53c6fbba9a898708e465260b6') {
    address = '0x0bc529c00C6401aEF6D220BE8C6Ea1667F6Ad93e'
  }
  if (address?.toLowerCase() === '0xad684e79ce4b6d464f2ff7c3fd51646892e24b96'.toLowerCase()) {
    customPath = autonio
  }

  if (address?.toLowerCase() === '0x800eb319e3f0e962d3ca8d625c871b8f1bdf2bc8'.toLowerCase()) {
    customPath = 'https://assets.coingecko.com/coins/images/2138/small/singularitynet.png?1548609559'
  }

  if (address?.toLowerCase() === '0x0cfc9a713a5c17bc8a5ff0379467f6558bacd0e0'.toLowerCase()) {
    customPath = 'https://assets.coingecko.com/coins/images/14474/small/graphlinq_logo.jpg?1616397109'
  }

  if (address?.toLowerCase() === '0x60ac2e84078ce30cbc68e3d7b18bcf613271ce6b'.toLowerCase()) {
    customPath = 'https://assets.coingecko.com/coins/images/14048/small/aloha.png?1615527835'
  }

  if (address?.toLowerCase() === '0x462d8d82c2b2d2ddabf7f8a93928de09d47a5807'.toLowerCase()) {
    customPath = 'https://raw.githubusercontent.com/totoptech/niox-token-list/main/logos/logo-bzb.png'
  }

  if (address?.toLowerCase() === '0xd7ecf95Cf7eF5256990BeAf4ac895cD9e64cb947'.toLowerCase()) {
    customPath = 'https://assets.coingecko.com/coins/images/10805/small/J51iIea.png?1583891599'
  }

  if (address?.toLowerCase() === '0x4d8181f051E617642e233Be09Cea71Cc3308ffD4'.toLowerCase()) {
    customPath = 'https://raw.githubusercontent.com/totoptech/niox-token-list/main/logos/logo-digi.svg'
  }

  if (address?.toLowerCase() === '0xdbf31df14b66535af65aac99c32e9ea844e14501'.toLowerCase()) {
    customPath = 'https://assets.coingecko.com/coins/images/11370/small/renBTC.png?1589985711'
  }

  if (address?.toLowerCase() === '0x59e991B37b35Ac2187113dFdB5D60D2e887eEec1'.toLowerCase()) {
    customPath = 'https://raw.githubusercontent.com/totoptech/niox-token-list/main/logos/sdao.png'
  }

  if (address?.toLowerCase() === '0x190Eb8a183D22a4bdf278c6791b152228857c033'.toLowerCase()) {
    customPath = 'https://assets.coingecko.com/coins/images/2138/small/singularitynet.png?1548609559'
  }

  if (address?.toLowerCase() === '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619') {
    return (
      <StyledEthereumLogo size={size} {...rest}>
        <img
          src={EthereumLogo}
          style={{
            boxShadow: '0px 6px 10px rgba(0, 0, 0, 0.075)',
            borderRadius: '24px',
          }}
          alt=""
        />
      </StyledEthereumLogo>
    )
  }

  let path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
    address
  )}/logo.png`

  if (customPath) {
    path = customPath
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
