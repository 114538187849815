import React /*, { useState, useEffect }*/ from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import LocalLoader from '../LocalLoader'
import utc from 'dayjs/plugin/utc'
import { Box, Flex } from 'rebass'
import styled from 'styled-components'

import { CustomLink } from '../Link'
import { Divider } from '..'
import { withRouter } from 'react-router-dom'
import { formattedNum } from '../../utils'
import { TYPE } from '../../Theme'

dayjs.extend(utc)

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 10px 1.5fr 1fr 1fr;
  grid-template-areas: 'number name pair value';
  padding: 0 4px;

  > * {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 10px 1.5fr 1fr 1fr;
    grid-template-areas: 'number name pair value';
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'name pair value';
  }
`

const ListWrapper = styled.div``

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`

function UserList({ userSwaps, disbaleLinks, totalCount }) {
  const below600 = useMedia('(max-width: 600px)')
  const below800 = useMedia('(max-width: 800px)')

  const ListItem = ({ swap, index }) => {
    return (
      <DashGrid style={{ height: '48px' }} disbaleLinks={disbaleLinks} focus={true}>
        {!below600 && (
          <DataText area="number" fontWeight="500">
            {index}
          </DataText>
        )}
        <DataText area="name" fontWeight="500" justifyContent="flex-start">
          <CustomLink
            style={{ marginLeft: below600 ? 0 : '1rem', whiteSpace: 'nowrap' }}
            to={'/account/' + swap.account}
          >
            {below800 ? swap.account.slice(0, 4) + '...' + swap.account.slice(38, 42) : swap.account}
          </CustomLink>
        </DataText>

        <DataText area="value">{formattedNum(swap.volume, true)}</DataText>
      </DashGrid>
    )
  }

  const userList =
    userSwaps &&
    userSwaps.slice(0, totalCount).map((swap, index) => {
      return (
        <div key={index}>
          <ListItem key={index} index={index + 1} swap={swap} />
          <Divider />
        </div>
      )
    })

  return (
    <ListWrapper>
      <DashGrid center={true} disbaleLinks={disbaleLinks} style={{ height: 'fit-content', padding: ' 0 0 1rem 0' }}>
        {!below600 && (
          <Flex alignItems="center" justifyContent="flex-start">
            <TYPE.main area="number">#</TYPE.main>
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.main area="name">Account</TYPE.main>
        </Flex>
        <Flex alignItems="center" justifyContent="flexEnd">
          <TYPE.main area="pair">Generated Volume</TYPE.main>
        </Flex>
      </DashGrid>
      <List p={0}>{!userList ? <LocalLoader /> : userList}</List>
    </ListWrapper>
  )
}

export default withRouter(UserList)
